<script setup lang="ts">

</script>

<template>
	<svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M15.4596 15.369V15.3671L15.4761 15.3277L18.2286 1.45065V1.4066C18.2286 1.06059 18.1001 0.758635 17.8229 0.577829C17.5797 0.419051 17.2998 0.408038 17.1034 0.422723C16.9206 0.439401 16.7402 0.47634 16.5656 0.532858C16.491 0.556638 16.4175 0.583584 16.3453 0.613623L16.3334 0.618212L0.987879 6.63801L0.98329 6.63985C0.936276 6.6548 0.890544 6.67353 0.84654 6.69583C0.737464 6.74499 0.632765 6.80332 0.533572 6.87021C0.336247 7.00605 -0.0391302 7.32544 0.0241975 7.83206C0.0765117 8.25241 0.365616 8.51857 0.561106 8.65715C0.676236 8.73843 0.800344 8.80618 0.930976 8.85907L0.960346 8.87192L0.969524 8.87467L0.975948 8.87742L3.66141 9.78145C3.65162 9.95032 3.66906 10.1201 3.71372 10.2908L5.05829 15.3928C5.13174 15.6709 5.29046 15.9191 5.51216 16.1023C5.73385 16.2856 6.00739 16.3948 6.29435 16.4147C6.58131 16.4345 6.86728 16.364 7.11209 16.213C7.35691 16.062 7.54827 15.8381 7.65931 15.5727L9.75922 13.3278L13.3652 16.0922L13.4166 16.1142C13.7443 16.2574 14.0499 16.3024 14.3298 16.2647C14.6098 16.2262 14.8319 16.1087 14.9989 15.9756C15.1921 15.819 15.3461 15.6195 15.4486 15.3928L15.456 15.3772L15.4587 15.3717L15.4596 15.369ZM5.04452 9.94023C5.02963 9.88362 5.03317 9.82373 5.05463 9.76927C5.07608 9.71481 5.11433 9.6686 5.16383 9.63736L14.2693 3.85527C14.2693 3.85527 14.8052 3.52945 14.786 3.85527C14.786 3.85527 14.8814 3.91217 14.5942 4.17925C14.3225 4.43347 8.10444 10.4368 7.47483 11.0443C7.44066 11.079 7.41628 11.122 7.40416 11.1692L6.38909 15.0422L5.04452 9.94023Z" fill="#7059FF"/>
	</svg>
</template>

<style scoped lang="scss">

</style>