<script setup lang="ts">

</script>

<template>
	<svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect y="0.917969" width="56" height="56" rx="5" fill="#7059FF"/>
		<g clip-path="url(#clip0_1890_2242-505222)">
			<path d="M34.6392 41.0344C33.5592 41.0344 32.3192 40.7544 30.9592 40.2344C28.2392 39.1544 25.2392 37.0744 22.5592 34.3944C19.8792 31.7144 17.7992 28.7144 16.7192 25.9544C15.5192 22.9944 15.6392 20.5544 17.0392 19.1944C17.0792 19.1544 17.1592 19.1144 17.1992 19.0744L20.5592 17.0744C21.3992 16.5944 22.4792 16.8344 23.0392 17.6344L25.3992 21.1544C25.9592 21.9944 25.7192 23.1144 24.9192 23.6744L23.4792 24.6744C24.5192 26.3544 27.4792 30.4744 32.1992 33.4344L33.0792 32.1544C33.7592 31.1944 34.8392 30.9144 35.7192 31.5144L39.2392 33.8744C40.0392 34.4344 40.2792 35.5144 39.7992 36.3544L37.7992 39.7144C37.7592 39.7944 37.7192 39.8344 37.6792 39.8744C36.9592 40.6344 35.9192 41.0344 34.6392 41.0344ZM18.2392 20.5544C17.4792 21.3944 17.5192 23.1144 18.3992 25.3144C19.3992 27.8344 21.3192 30.5944 23.8392 33.1144C26.3192 35.5944 29.1192 37.5144 31.5992 38.5144C33.7592 39.3944 35.4792 39.4344 36.3592 38.6744L38.2792 35.3944C38.2792 35.3544 38.2792 35.3544 38.2792 35.3144L34.7592 32.9544C34.7592 32.9544 34.6792 32.9944 34.5992 33.1144L33.7192 34.3944C33.1592 35.1944 32.0792 35.4344 31.2792 34.9144C26.2392 31.7944 23.1192 27.4344 21.9992 25.6744C21.4792 24.8344 21.6792 23.7544 22.4792 23.1944L23.9192 22.1944V22.1544L21.5592 18.6344C21.5592 18.5944 21.5192 18.5944 21.4792 18.6344L18.2392 20.5544Z" fill="white"/>
			<path d="M38.6393 27.5153C38.1593 27.5153 37.7993 27.1553 37.7593 26.6753C37.4393 22.6353 34.1193 19.3953 30.0393 19.1153C29.5593 19.0753 29.1593 18.6753 29.1993 18.1553C29.2393 17.6753 29.6393 17.2753 30.1593 17.3153C35.1193 17.6353 39.1593 21.5553 39.5593 26.5153C39.5993 26.9953 39.2393 27.4353 38.7193 27.4753C38.7193 27.5153 38.6793 27.5153 38.6393 27.5153Z" fill="white"/>
			<path d="M34.6793 27.8754C34.2393 27.8754 33.8393 27.5554 33.7993 27.0754C33.5593 24.9154 31.8793 23.2354 29.7193 22.9554C29.2393 22.9154 28.8793 22.4354 28.9193 21.9554C28.9593 21.4754 29.4393 21.1154 29.9193 21.1554C32.9193 21.5154 35.2393 23.8354 35.5993 26.8354C35.6393 27.3154 35.3193 27.7554 34.7993 27.8354C34.7193 27.8754 34.6793 27.8754 34.6793 27.8754Z" fill="white"/>
		</g>
		<defs>
			<clipPath id="clip0_1890_2242-505222">
				<rect width="25.6" height="25.6" fill="white" transform="translate(15.1992 16.1133)"/>
			</clipPath>
		</defs>
	</svg>
</template>

<style scoped lang="scss">

</style>