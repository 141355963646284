import { default as about7DGIhdOT4FMeta } from "/AuraTour/pages/about.vue?macro=true";
import { default as bookings8AxMJtvMFGMeta } from "/AuraTour/pages/admin/bookings.vue?macro=true";
import { default as create_45listingjHrOGkMswPMeta } from "/AuraTour/pages/admin/create-listing.vue?macro=true";
import { default as _91id_93B6d4By9mGhMeta } from "/AuraTour/pages/admin/edit-listing/[id].vue?macro=true";
import { default as listing_45searchG5mIYJ0Lf0Meta } from "/AuraTour/pages/admin/listing-search.vue?macro=true";
import { default as moderation0QSgyaRYLAMeta } from "/AuraTour/pages/admin/moderation.vue?macro=true";
import { default as statistics2VerdhwaS3Meta } from "/AuraTour/pages/admin/statistics.vue?macro=true";
import { default as _91userId_93YSr0WAUnw1Meta } from "/AuraTour/pages/admin/user/[userId].vue?macro=true";
import { default as contactsPEPL7SxSa8Meta } from "/AuraTour/pages/contacts.vue?macro=true";
import { default as favoritesVsWW0HrU6JMeta } from "/AuraTour/pages/favorites.vue?macro=true";
import { default as helpGTlM3CFuPCMeta } from "/AuraTour/pages/help.vue?macro=true";
import { default as indexuIvjYv2GHHMeta } from "/AuraTour/pages/index.vue?macro=true";
import { default as _91id_93Gjd4o3jDwQMeta } from "/AuraTour/pages/listing/[id].vue?macro=true";
import { default as bookingsUTHFeZiwhUMeta } from "/AuraTour/pages/lk/bookings.vue?macro=true";
import { default as create_45listingxmhe4XbIBmMeta } from "/AuraTour/pages/lk/create-listing.vue?macro=true";
import { default as _91id_93VU9HYkDshzMeta } from "/AuraTour/pages/lk/edit-listing/[id].vue?macro=true";
import { default as listingsJZ9gn690olMeta } from "/AuraTour/pages/lk/listings.vue?macro=true";
import { default as profile9JmiMrg9WMMeta } from "/AuraTour/pages/lk/profile.vue?macro=true";
import { default as obrabotka_personalnyh_dannyhsMeCffvBDeMeta } from "/AuraTour/pages/obrabotka_personalnyh_dannyh.vue?macro=true";
import { default as polzovatelskoe_soglasheniedDhAMeqYqjMeta } from "/AuraTour/pages/polzovatelskoe_soglashenie.vue?macro=true";
import { default as _91typeSlug_93bK0J67AhB7Meta } from "/AuraTour/pages/search/city/[citySlug]/[typeSlug].vue?macro=true";
import { default as indexlWqgiFS6KzMeta } from "/AuraTour/pages/search/city/[citySlug]/index.vue?macro=true";
import { default as indexeMD8haUKSZMeta } from "/AuraTour/pages/search/index.vue?macro=true";
import { default as _91typeSlug_93oWo5z5zKfTMeta } from "/AuraTour/pages/search/type/[typeSlug].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/AuraTour/pages/about.vue")
  },
  {
    name: "admin-bookings",
    path: "/admin/bookings",
    meta: bookings8AxMJtvMFGMeta || {},
    component: () => import("/AuraTour/pages/admin/bookings.vue")
  },
  {
    name: "admin-create-listing",
    path: "/admin/create-listing",
    meta: create_45listingjHrOGkMswPMeta || {},
    component: () => import("/AuraTour/pages/admin/create-listing.vue")
  },
  {
    name: "admin-edit-listing-id",
    path: "/admin/edit-listing/:id()",
    meta: _91id_93B6d4By9mGhMeta || {},
    component: () => import("/AuraTour/pages/admin/edit-listing/[id].vue")
  },
  {
    name: "admin-listing-search",
    path: "/admin/listing-search",
    meta: listing_45searchG5mIYJ0Lf0Meta || {},
    component: () => import("/AuraTour/pages/admin/listing-search.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    meta: moderation0QSgyaRYLAMeta || {},
    component: () => import("/AuraTour/pages/admin/moderation.vue")
  },
  {
    name: "admin-statistics",
    path: "/admin/statistics",
    meta: statistics2VerdhwaS3Meta || {},
    component: () => import("/AuraTour/pages/admin/statistics.vue")
  },
  {
    name: "admin-user-userId",
    path: "/admin/user/:userId()",
    meta: _91userId_93YSr0WAUnw1Meta || {},
    component: () => import("/AuraTour/pages/admin/user/[userId].vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/AuraTour/pages/contacts.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/AuraTour/pages/favorites.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/AuraTour/pages/help.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/AuraTour/pages/index.vue")
  },
  {
    name: "listing-id",
    path: "/listing/:id()",
    component: () => import("/AuraTour/pages/listing/[id].vue")
  },
  {
    name: "lk-bookings",
    path: "/lk/bookings",
    meta: bookingsUTHFeZiwhUMeta || {},
    component: () => import("/AuraTour/pages/lk/bookings.vue")
  },
  {
    name: "lk-create-listing",
    path: "/lk/create-listing",
    meta: create_45listingxmhe4XbIBmMeta || {},
    component: () => import("/AuraTour/pages/lk/create-listing.vue")
  },
  {
    name: "lk-edit-listing-id",
    path: "/lk/edit-listing/:id()",
    meta: _91id_93VU9HYkDshzMeta || {},
    component: () => import("/AuraTour/pages/lk/edit-listing/[id].vue")
  },
  {
    name: "lk-listings",
    path: "/lk/listings",
    meta: listingsJZ9gn690olMeta || {},
    component: () => import("/AuraTour/pages/lk/listings.vue")
  },
  {
    name: "lk-profile",
    path: "/lk/profile",
    meta: profile9JmiMrg9WMMeta || {},
    component: () => import("/AuraTour/pages/lk/profile.vue")
  },
  {
    name: "obrabotka_personalnyh_dannyh",
    path: "/obrabotka_personalnyh_dannyh",
    component: () => import("/AuraTour/pages/obrabotka_personalnyh_dannyh.vue")
  },
  {
    name: "polzovatelskoe_soglashenie",
    path: "/polzovatelskoe_soglashenie",
    component: () => import("/AuraTour/pages/polzovatelskoe_soglashenie.vue")
  },
  {
    name: "search-city-citySlug-typeSlug",
    path: "/search/city/:citySlug()/:typeSlug()",
    component: () => import("/AuraTour/pages/search/city/[citySlug]/[typeSlug].vue")
  },
  {
    name: "search-city-citySlug",
    path: "/search/city/:citySlug()",
    component: () => import("/AuraTour/pages/search/city/[citySlug]/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/AuraTour/pages/search/index.vue")
  },
  {
    name: "search-type-typeSlug",
    path: "/search/type/:typeSlug()",
    component: () => import("/AuraTour/pages/search/type/[typeSlug].vue")
  }
]