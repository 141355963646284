<script setup lang="ts">

</script>

<template>
	<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14.8164 4.12496C12.4242 0.416452 7.52527 -0.682364 3.73664 1.61828C0.0395816 3.91893 -1.1508 8.93228 1.24141 12.6293L1.43599 12.9269L0.634774 15.9258L3.63363 15.1246L3.93122 15.3192C5.22462 16.0174 6.63248 16.418 8.02889 16.418C9.52832 16.418 11.0277 16.0174 12.3211 15.2161C16.0182 12.8125 17.117 7.90214 14.8164 4.10207V4.12496ZM12.7218 11.8396C12.3211 12.4348 11.8175 12.8354 11.1193 12.9384C10.7187 12.9384 10.2151 13.133 8.22348 12.3432C6.52947 11.542 5.12161 10.2371 4.12581 8.7377C3.53061 8.03949 3.22157 7.13526 3.13 6.23102C3.13 5.4298 3.4276 4.7316 3.93122 4.22797C4.12581 4.03339 4.33183 3.93038 4.52642 3.93038H5.03004C5.22462 3.93038 5.43065 3.93038 5.53366 4.33099C5.72825 4.83461 6.23187 6.03644 6.23187 6.13946C6.33488 6.24247 6.2891 7.00935 5.83126 7.4443C5.57945 7.73045 5.53366 7.7419 5.63668 7.94792C6.03729 8.54312 6.54091 9.14976 7.03309 9.65338C7.62828 10.157 8.23492 10.5576 8.93313 10.8552C9.12771 10.9582 9.33374 10.9582 9.43675 10.7522C9.53977 10.5576 10.0319 10.054 10.238 9.84796C10.4326 9.65338 10.5356 9.65338 10.7416 9.74495L12.344 10.5462C12.5386 10.6492 12.7446 10.7407 12.8477 10.8438C12.9507 11.1414 12.9507 11.542 12.7446 11.8396H12.7218Z" fill="#7059FF"/>
	</svg>
</template>

<style scoped lang="scss">

</style>