<script setup lang="ts">

</script>

<template>
	<svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect y="0.917969" width="56" height="56" rx="5" fill="#7059FF"/>
		<g clip-path="url(#clip0_1890_2256-731749)">
			<path d="M38.7599 23.878L29.5199 17.398C28.5999 16.758 27.3999 16.758 26.4799 17.398L17.2399 23.878C16.5199 24.358 16.1199 25.198 16.1199 26.038V38.318C16.1199 39.758 17.3199 40.958 18.7599 40.958H37.2799C38.7199 40.958 39.9199 39.758 39.9199 38.318V26.038C39.9199 25.158 39.4799 24.358 38.7599 23.878ZM31.6399 39.118H24.3599V34.518C24.3599 34.238 24.4799 33.998 24.7199 33.838L27.5199 31.878C27.7999 31.678 28.1999 31.678 28.4799 31.878L31.2799 33.838C31.5199 33.998 31.6399 34.238 31.6399 34.518V39.118V39.118ZM38.1199 38.278C38.1199 38.758 37.7599 39.118 37.2799 39.118H33.4399V34.518C33.4399 33.678 33.0399 32.838 32.3199 32.358L29.5199 30.398C28.5999 29.758 27.3999 29.758 26.4799 30.398L23.6799 32.358C22.9599 32.838 22.5599 33.678 22.5599 34.518V39.118H18.7199C18.2399 39.118 17.8799 38.758 17.8799 38.278V26.038C17.8799 25.758 17.9999 25.518 18.2399 25.358L27.5199 18.838C27.6799 18.718 27.8399 18.678 27.9999 18.678C28.1599 18.678 28.3199 18.718 28.4799 18.838L37.7599 25.318C37.9999 25.478 38.1199 25.718 38.1199 25.998V38.278V38.278Z" fill="white"/>
		</g>
		<defs>
			<clipPath id="clip0_1890_2256-731749">
				<rect width="25.6" height="25.6" fill="white" transform="translate(15.2 16.1172)"/>
			</clipPath>
		</defs>
	</svg>
</template>

<style scoped lang="scss">

</style>