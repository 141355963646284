<script setup lang="ts">

</script>

<template>
	<svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect y="0.917969" width="56" height="56" rx="5" fill="#7059FF"/>
		<g clip-path="url(#clip0_1890_2263-744720)">
			<path d="M37.5999 19.957H18.3999C17.0399 19.957 15.8799 21.077 15.8799 22.477V35.437C15.8799 36.797 16.9999 37.957 18.3999 37.957H37.5999C38.9599 37.957 40.1199 36.837 40.1199 35.437V22.437C40.1199 21.077 38.9599 19.957 37.5999 19.957ZM37.5999 21.757C37.6399 21.757 37.6799 21.757 37.7199 21.757L27.9999 27.997L18.2799 21.757C18.3199 21.757 18.3599 21.757 18.3999 21.757H37.5999ZM37.5999 36.077H18.3999C17.9999 36.077 17.6799 35.757 17.6799 35.357V23.517L27.0399 29.517C27.3199 29.717 27.6399 29.797 27.9599 29.797C28.2799 29.797 28.5999 29.717 28.8799 29.517L38.2399 23.517V35.397C38.3199 35.797 37.9999 36.077 37.5999 36.077Z" fill="white"/>
		</g>
		<defs>
			<clipPath id="clip0_1890_2263-744720">
				<rect width="25.6" height="25.6" fill="white" transform="translate(15.2 16.1172)"/>
			</clipPath>
		</defs>
	</svg>
</template>

<style scoped lang="scss">

</style>